import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@components-new/organisms';
import { dataTableColumnsProps } from '@components-new/common/types';
import { ContainerNameBottom, Criteria, DownloadText, DownloadTitle, LastAudit, Link, List, NoData, SubContractorName } from './Inventory_.style';
import { Chip, Container } from '@components-new/atoms';
import { conformityStatus } from '@components-new/common/constants';
import dayjs from 'dayjs';
import { Modal } from '@components/modal/Modal';
import { MdOutlineFileDownload } from 'react-icons/md';
import { Button } from '@components/Button';
import restricted from '../../../../assets/restricted.svg';
import { CSM_MANAGERS } from '@utils/constants';
import { selectActiveAccount } from 'src/reducers/account.reducer';
import { useSelector } from 'react-redux';

import { downloadAllSubContractorsInventory, downloadFilteredSubContractorsInventory, downloadLegalOfficerClientInventory, fetchAccountSubContractors, fetchAllSubContractors, fetchLegalOfficerClientsSubContractors } from '@api/audit';
import { compareQueryToSubContractorName } from '@utils/utils';
import InventoryHeader from './Header';
import { createAndSaveInventoryBlob, sortSubcontractorsByStatus } from '../AuditsAndInventory.utils';
import { InventoryResponse } from '../AuditsAndInventory.types';
import Loader from '@components/loader/loader';
import { toast } from 'react-toastify';

const Inventory = ({ currentAccount, selectedEntity, actionPlanActived }) => {
  const { t } = useTranslation();

  const activeAccount = useSelector(selectActiveAccount);

  const [inventory, setInventory] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>(null);
  const [selectedFinality, setSelectedFinality] = useState<any>(null);
  const [displayModalDownload, setDisplayModalDownload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

  const clientColumns: dataTableColumnsProps[] = [
    {
      field: 'subContractorName',
      headerName: t('common.subContractor'),
      width: 'col-1-75',
      bolder: true,
      center: false,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'finalityCol',
      headerName: t('common.finality'),
      width: 'col-1-5',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'servers',
      headerName: t('common.servers'),
      width: 'col-1-25',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'dpa',
      headerName: t('common.DPA'),
      width: 'col-1-25',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'nonUETransfer',
      headerName: t('common.transfersOutsideUE'),
      width: 'col-1-25',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'cct',
      headerName: t('common.warranties'),
      width: 'col-1-25',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'requiredAction',
      headerName: t('common.requiredAction'),
      width: 'col-1-25',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'status',
      headerName: t('common.conformity'),
      width: 'col-1-25',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
  ];

  useEffect(() => {
    const fetchAndSetInventory = async () => {
      let response;
      setLoading(true)

      if (currentAccount._id === 'all') {
        response = await fetchAllSubContractors();
      } else if (currentAccount._id === 'myClients') {
        response = await fetchLegalOfficerClientsSubContractors(activeAccount.users[0].email);
      } else {
        response = await fetchAccountSubContractors(currentAccount?._id);
      }

      if (response?.success) {
        const sortedData = sortSubcontractorsByStatus(response.data);
        setInventory(sortedData);
      }
      else {
        toast.error(t('inventory.error'))
        console.error(response.error)
      }

      setLoading(false)
    };

    fetchAndSetInventory();
  }, [currentAccount?._id, activeAccount?.users[0]?.email]);

  const rawClientRows = useMemo(() => {
    return inventory
      ?.map((subContractor) => {
        return {
          ...subContractor,
          subContractorName:
            () => {
              return (
                <div>
                  <SubContractorName>{subContractor.clientGivenName}</SubContractorName>
                  <ContainerNameBottom>
                    <div>{subContractor.responsableEmail}</div>
                    {subContractor.lastAuditDate && <LastAudit>{t('inventory.lastAudit')} : {dayjs(subContractor.lastAuditDate).format('DD/MM/YYYY')}</LastAudit>}
                  </ContainerNameBottom>
                </div>)
            },
          finalityCol:
            () => {
              if (subContractor.status !== 'ongoing') {
                return subContractor.finality
              } else {
                return <Chip label={t("inventory.ongoingAudit")} color={'#3D3D3D'} backgroundColor={'#F2F2F5'} width='100%' justifyContent='center' fontSize='1.2rem'/>;
              }
            },
          servers: () => {
            if (subContractor.status !== 'ongoing') {
              return <List>{subContractor.serversLocation.map((location) => {
                return (
                  <li>{location}</li>
                )
              })}</List>
            }
          },
          dpa: () => {
            if (subContractor.status !== 'ongoing') {
              return (
                  subContractor.requiredAction?.some(action => action.text?.includes("Signer le DPA")) ? <div>{t('inventory.waitingSignDPA')}</div>
                  : subContractor.dpa.length ? <div>{subContractor.dpa[0].text || subContractor.dpa[0]} <br /> {subContractor.dpa[0].link && typeof subContractor.dpa[0].link === 'string' && <Link href={subContractor.dpa[0].link}>{t('inventory.accessibleHere')}</Link>}</div>
                  : <div>{t('inventory.missingDPA')}</div>
              )
            }
          },
          nonUETransfer: () => {
            if (subContractor.status !== 'ongoing') {
              return (
                subContractor.nonUETransfer.length ? <List>{subContractor.nonUETransfer.map((location) => {
                  return (
                    <li>{location}</li>
                  )
                })}</List>
                  : <div>{t('common.no')}</div>
              )
            }
          },
          cct: () => {
            if (subContractor.status !== 'ongoing') {
              return (
                <List>
                  {subContractor.cct.map((action) => {
                    if (typeof action === 'string') {
                      return <li>{action}</li>
                    } else {
                      return (
                        <li><span>{action.text}</span><br /><Link href={action.link}>{t('inventory.accessibleHere')}</Link></li>
                      )
                    }
                  })}
                </List>
              )
            }
          },
          requiredAction: () => {
            if (subContractor.status !== 'ongoing') {
              return (
                <List>
                  {subContractor.requiredAction.map((action) => {
                    return (
                      <li><span>{action.text}</span><br /><Link href={action.link}>{t('inventory.accessibleHere')}</Link></li>
                    )
                  })}
                </List>
              )
            }
          },
          status: () => {
            if (subContractor.status !== 'ongoing') {
              const { label, color } = conformityStatus[subContractor.status];
              return <Chip label={t(label)} color={color} fontSize={'1.3rem'}/>;
            }
          },
          customSizeCols: subContractor.status === 'ongoing' && ['col-1-75', 'col-10']
        };
      });
  }, [inventory]);

  const finalitiesList: string[] = useMemo(() => {
    if (!rawClientRows?.length) return [];

    const finalities: string[] = rawClientRows
      .map(row => row.finality)
      .filter((finality): finality is string => Boolean(finality));

    return [...new Set(finalities)];
  }, [rawClientRows]);

  const clientRows = useMemo(() => {
    if (!rawClientRows) return [];

    return rawClientRows.filter(elt => {
      return selectedEntity ? elt.initiatorEntity === selectedEntity._id.toString() : true;
    }).filter(elt => {
      return compareQueryToSubContractorName(elt.clientGivenName, searchQuery);
    }).filter(elt => {
      return selectedFinality ? elt.finality === selectedFinality.label : true;
    });
  }, [rawClientRows, searchQuery, selectedFinality, selectedEntity]);

  const handleDownloadInventory = async () => {
    try {
      setLoadingDownload(true)
      let response: InventoryResponse;
      let fileName = 'Inventaire_SousTraitants_';
      switch (currentAccount._id) {
        case 'all':
          fileName += '_AllClients_';
          response = await downloadAllSubContractorsInventory();
          break;

        case 'myClients':
          fileName += '_MyClients_';
          const legalOfficerEmail = activeAccount.users[0].email;
          response = await downloadLegalOfficerClientInventory(legalOfficerEmail);
          break;

        default:
          fileName += '_';
          response = await downloadFilteredSubContractorsInventory(
            currentAccount._id,
            selectedEntity?._id,
            selectedFinality?.label
          );
      }

      if (!response.success || !response.data) {
        throw new Error(response.error || 'Failed to download inventory');
      }

      createAndSaveInventoryBlob(response.data.data, fileName);

      setDisplayModalDownload(false)
    } catch (error) {
      toast.error(t('inventory.downloadError'))
    } finally {
      setLoadingDownload(false)
    }
  };

  const showInventoryDownloadModal = useMemo(() => {
    if (CSM_MANAGERS.includes(activeAccount._id.toString()) || currentAccount._id !== 'all') {
      return true;
    }
    return false;
  }, [activeAccount._id, currentAccount._id]);


  return (
    <>
      {loading ?
        <Container flex justifyContent='center' mt='200px'>
          <Loader loaderOnly={true} />
        </Container> :
          !actionPlanActived ?
        <NoData dangerouslySetInnerHTML={{
          __html: t('inventory.noDataActionPlan', { account: currentAccount?.companyName }),
        }}>
        </NoData> : !clientRows.length && !searchQuery ?
        <NoData dangerouslySetInnerHTML={{
          __html: t('inventory.noData', { account: currentAccount?.companyName } ),
        }}></NoData> :        
        <DataTable
        loading={false}
        rows={clientRows}
        columns={clientColumns}
        tableInventory={true}
        noDataText={t('inventory.noDataSearch')}
        customHeader={
          <InventoryHeader
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            displayModalDownload={displayModalDownload}
            setDisplayModalDownload={setDisplayModalDownload}
            selectedFinality={selectedFinality}
            setSelectedFinality={setSelectedFinality}
            finalities={finalitiesList}
          />
        }
        searchPlaceholder={t('actionPlan.search_actions')}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        />
      }
      {showInventoryDownloadModal ? (
        <Modal
          size={{ width: '630px' }}
          top="25%"
          rowGap="0"
          show={displayModalDownload}
          onClose={() => setDisplayModalDownload(false)}
          closeColor="#00000057"
        >
        {loadingDownload ? (
            <Container flex justifyContent='center' mt='100px' mb='100px'>
            <Loader loaderOnly={true} />
          </Container>
        ) : (
          <>
            <DownloadTitle>
              <MdOutlineFileDownload color={'#3B4B5F'} size={22} />
              <span>{t('common.downloading')} :</span>
            </DownloadTitle>
            <DownloadText
              dangerouslySetInnerHTML={{
                __html: t('inventory.downloadText'),
              }}
            />
            <Criteria>
              <p>
                {t('common.client')} :{' '}
                {currentAccount?._id === 'all'
                  ? t('common.allClients')
                  : currentAccount?._id === 'myClients'
                  ? t('common.myClients')
                  : currentAccount?.companyName}
              </p>
              {selectedEntity && (
                <p>
                  {t('common.entity')} : {selectedEntity.name}
                </p>
              )}
              {selectedFinality && (
                <p>
                  {t('common.finality')} : {selectedFinality.label}
                </p>
              )}
            </Criteria>
            <Container alignSelf="center" mt="34px">
              <Button
                customColor="#326C82"
                label={t('common.confirmDownload')}
                onClick={handleDownloadInventory}
              />
            </Container>
          </>
        )}
        </Modal>
    ) : (
    <Modal
      size={{ width: '680px' }}
      top="25%"
      rowGap="0"
      show={displayModalDownload}
      onClose={() => setDisplayModalDownload(false)}
      closeColor="#00000057">
      <DownloadTitle>
        <img src={restricted} alt="restricted" />
        <span>{t('inventory.restrictedTitle')}</span>
      </DownloadTitle>
      <Criteria>
        <p>{t('inventory.restrictedP1')}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: t('inventory.restrictedP2'),
          }}
        />
      </Criteria>
    </Modal>
    )}
    </>
  )
};

export default Inventory;


