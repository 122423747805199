import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { IoIosArrowDown, IoIosArrowUp, IoIosLink, IoMdCheckmark } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';
import { TbCircleDashedCheck, TbEye } from 'react-icons/tb';
import { RiErrorWarningLine } from 'react-icons/ri';
import { MdKeyboardArrowLeft, MdOutlineEmail } from 'react-icons/md';

import { auditsStatus } from '@components-new/common/constants';
import { Chip, Container } from '@components-new/atoms';
import { SearchBar } from '@components-new/molecules';
import { FaRegUserCircle } from 'react-icons/fa';
import { BsFillPinFill, BsPlusCircle } from 'react-icons/bs';

import { Modal } from '@components/modal/Modal';
import { Button } from '@components/Button';
import { SharedItem } from '@components/collaborators/SharedItem';
import { FileExplorer } from '@components/file-explorer/FileExplorer';

import { selectActiveAccount, updateActionPlan } from 'src/reducers/account.reducer';

import useDidUpdate from '@hooks/useDidUpdate';

import { getAuditQuestionInfosByName, normalizeString } from '@utils/utils';
import { fetchContentfulEntryById, fetchSubContractorResponses, getAccountById, updateSubContractorFinality, validateOrRefuseAudit } from '@utils/api';
import { LEGAL_OPS } from '@utils/constants';

import { finalities, newSubContractorInitialState, ST_ENTRY_ID } from './Audit.constants';
import {
  ActionsContainer, BackgroundResponse, ContactInfoModal, ContainerSubTitleModal,
  ContainerTitle, DowngradeButton, Link, List, ListItem, NewSubContractor, NoAnswers, NoSubContractor,
  ResponseContainer, ResponseHeader, Span, StatusModal, SubContractorName, TitleModal, Separator, LegalOpsElement,
  NewSubContractorTitle, Label, StyledInputComponent,
  RemindersTitle,
  RemindersWarning,
  RemindersBody,
  LabelReminder,
  ReminderChoice,
  ContainerConfirm,
  TextReturn,
  AuditsCompletedText,
  SeparatorCompleted
} from './Audit_.style';

import { createSubContractor, fetchEntityAuditActions } from '@api/audit';
import { toast } from 'react-toastify';
import Loader from '@components/loader/loader';
import { NoData } from '../inventory/Inventory_.style';

const Audits = ({ currentAccount, selectedEntity, setCurrentAccount, setSelectedEntity }) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const [audits, setAudits] = useState(auditsStatus);

  const [actionActive, setActionActive] = useState<any>('');
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [surveyData, setSurveyData] = useState(null);
  const [subContractorData, setSubContractorData] = useState(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const activeAccount = useSelector(selectActiveAccount);
  const [selectedFinality, setSelectedFinality] = useState<{ label: string; value: string } | null>(null);
  const [showAvailableDocuments, setShowAvailableDocuments] = useState({ show: false, fileName: null });
  const [displayModalNewSubContractor, setDisplayModalNewSubContractor] = useState(false);
  const [displayModalReminder, setDisplayModalReminder] = useState(false);
  const [displayModalConfirm, setDisplayModalConfirm] = useState<string>(null);
  const [nextReminder, setNextReminder] = useState(null);
  const [emailReminder, setEmailReminder] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Fetched Actions from API
  const [entityAuditActions, setEntityAuditActions] = useState(null)
  const [newSubContractor, setNewSubContractor] = useState(newSubContractorInitialState)

  const getFinalityOptions = () => {
    return finalities?.map((finality: string) => ({
      label: finality,
      value: finality,
    }));
  };

  // Fetch Subcontractor survey data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchContentfulEntryById(ST_ENTRY_ID);
        setSurveyData(result);
      } catch (err) {
        console.log("🚀 ~ err:", err)
      }
    };
    fetchData();
  }, []);

  useDidUpdate(async () => {
    const subContractorId = selectedAction?.subContractor?.id;
    if (!subContractorId) {
      setSubContractorData(null);
      return;
    }

    try {
      const result = await fetchSubContractorResponses(subContractorId);

      if (result && Object.keys(result).length > 0) {
        setSubContractorData(result);
        setEmailReminder(result?.responsableEmail || selectedAction?.subContractor?.contactEmail);
      } else {
        setSubContractorData(null);
      }
    } catch (error) {
      console.error("Error fetching subcontractor responses:", error);
      setSubContractorData(null);
    }
  }, [selectedAction?.subContractor?.id]);

  useEffect(() => {
    const updatedAudits: any = Object.keys(audits).reduce((acc, status) => {
      acc[status] = {
        ...audits[status],
        actions: [],
        actionsToVerify: []
      };
      return acc;
    }, {});

    entityAuditActions
      ?.filter((item) => item.category === "Audit" && item.active)
      .forEach((action) => {
        if (action.status === 'verify' && action.subContractorStatus === 'ongoing') {
          updatedAudits['completed'].actionsToVerify.push(action);
        }
        else {
          const actionStatus = action.status === 'verify' ? 'completed' : action.status
          if (updatedAudits[actionStatus]) {
            updatedAudits[actionStatus].actions.push(action);
          }
        }
      });
    setAudits(updatedAudits);
  }, [entityAuditActions]);

  const openPendingAudit = ((subContractor) => {
    if (actionActive === subContractor) {
      setActionActive('')
    } else {
      setActionActive(subContractor)
    }
  })

  const openModalAudit = ((action) => {
    setSelectedAction(action)
    setDisplayModal(true)
    setSelectedFinality({ label: action.finality, value: action.finality })
  })

  const Action = ({ action, index, statusName }) => {
    return (
      <>
        {statusName === 'pending' ?
          <ListItem
            key={index}
            active={actionActive === action?.subContractor && true}
            status={statusName}
            onClick={() => statusName === 'pending' && openPendingAudit(action.subContractor)}>
            <Container flex alignItems={actionActive === action?.subContractor ? 'flex-start' : 'center'} justifyContent='space-between'>
              <Container>
                <SubContractorName>{action?.subContractor?.name}</SubContractorName>
              </Container>
              <Container flex gap='5px'>
                {action.isLegalOpsCreation && <LegalOpsElement fontSize="0.8em" mt="-2px"><BsFillPinFill size={12} /><span>{t('common.legalOps')}</span></LegalOpsElement>}
                {actionActive === action?.subContractor ? <IoIosArrowUp size='25px' color='#3D3D3D' /> : <IoIosArrowDown size='25px' color='#C4C3C2' />}
              </Container>
            </Container>
            {actionActive === action?.subContractor &&
              <>
                <Container mr='25px'>
                  {actionActive === action?.subContractor && <NoSubContractor>{t('audits.noSubContractor')}</NoSubContractor>}
                </Container>
                <Container flex justifyContent='center'>
                  <DowngradeButton onClick={() => handleUpdateActionsStatus('downgrade', action)}><span>{t('common.downgrade').toUpperCase()}</span><RxCross2 size='20px' /></DowngradeButton>
                </Container>
              </>
            }
          </ListItem> :

          statusName === 'ongoing' ?
            <ListItem
              onClick={() => openModalAudit(action)}
              key={index}
              active={false}
              status={statusName}>
              <Container flex justifyContent='space-between'>
                <SubContractorName>{action?.subContractor?.name}</SubContractorName>
                {action.isLegalOpsCreation && <LegalOpsElement fontSize="0.8em" mt="-2px"><BsFillPinFill size={12} /><span>{t('common.legalOps')}</span></LegalOpsElement>}
              </Container>
            </ListItem> :

            statusName === 'completed' ?
              <ListItem
                onClick={() => openModalAudit(action)}
                key={index}
                active={false}
                status={statusName}>
                <Container flex justifyContent='space-between'>
                  <Container flex gap='10px' alignItems='center'>
                    <SubContractorName>{action?.subContractor?.name}</SubContractorName>
                    {action.subContractorStatus === 'non_conforme' && <Chip icon={RxCross2} color={'#FF7675'} backgroundColor={'#FFF1F1'} iconSize={15} height='20px' borderRadius='5px'></Chip>}
                    {action.subContractorStatus === 'conforme' && <Chip icon={IoMdCheckmark} color={'#00C6A1'} backgroundColor={'#00C6A114'} iconSize={15} height='20px' borderRadius='5px'></Chip>}
                  </Container>
                  {action.isLegalOpsCreation && <LegalOpsElement fontSize="0.8em" mt="-2px"><BsFillPinFill size={12} /><span>{t('common.legalOps')}</span></LegalOpsElement>}
                </Container>
                <Container mt='10px' alignItems='center' flex>
                  {action.subContractorStatus !== 'ongoing' ? <Link>{t('audits.viewSurvey')}</Link> : <Link>{t('audits.checkSurvey')}<span><TbEye color='#757575' size={20} /></span></Link>}
                </Container>
              </ListItem> :
              <></>
        }
      </>
    );
  };

  const ChipStatus = ({ selectedAction }) => {
    let color
    let backgroundColor
    let icon
    let text

    if (selectedAction?.subContractorStatus !== 'ongoing' && (selectedAction?.status === 'completed' || selectedAction?.status === 'verify')) {
      color = selectedAction?.subContractorStatus === 'conforme' ? '#00C6A1' : '#FF746F'
      backgroundColor = selectedAction?.subContractorStatus === 'conforme' ? '#00C6A114' : '#FFF1F1'
      icon = selectedAction?.subContractorStatus === 'conforme' ? TbCircleDashedCheck : RxCross2
      text = selectedAction?.subContractorStatus === 'conforme' ? t('common.compliant') : t('common.non_compliant')
    } else {
      const actionStatus = selectedAction?.status === 'ongoing' ? 'ongoing' : 'completed'
      color = audits[actionStatus]?.color
      backgroundColor = audits[actionStatus]?.backgroundColor
      icon = audits[actionStatus]?.icon
      text = selectedAction?.status === 'ongoing' ? t('common.inProgress') : t('common.toVerify')
    }

    return (
      <StatusModal color={color} backgroundColor={backgroundColor}>
        <Chip height='25px' icon={icon} iconSize={18} color={color} backgroundColor='transparent' />
        <Span>{text}</Span>
      </StatusModal>
    )
  }

  const formatSubcontractorQuestionAndResponses = (key, value) => {
    const questionInfo = getAuditQuestionInfosByName(surveyData, key);
    const questionLabel = questionInfo.label;
    if (Array.isArray(value)) {
      return { questionLabel, response: value.map(elt => questionInfo.options?.find(item => item.value === elt)?.label).join(', ') };
    }
    const response = questionInfo.options ? questionInfo.options?.find(item => item.value === value || value.includes(item.value))?.label : value;
    return { questionLabel, response };
  };

  const handleUpdateActionsStatus = async (upgrade, action) => {
    try {
      setLoading(true)
      const actions = [action]
      await dispatch(updateActionPlan({ accountId: currentAccount._id, entityId: selectedEntity._id, actions, upgrade: upgrade }));

      const updatedAccount = await getAccountById(currentAccount._id);
      setCurrentAccount(updatedAccount);

      const updatedEntity = updatedAccount.entities.find(
        (entity) => entity.id === selectedEntity.id
      );

      if (updatedEntity) {
        setSelectedEntity(updatedEntity);
      }

      fetchAudits()
      setLoading(false)
    } catch (error) {
      console.error("Error downgrading action:", error);
      toast.error(t('audits.downgradeError'))
    }
  };

  // TODO: set next reminder and handle

  const handleChangeReminder = () => {
    setNextReminder(null)
    setDisplayModalReminder(false)
  }

  const closeModal = () => {
    setNextReminder(null)
    setDisplayModalConfirm(null)
    setDisplayModal(false)
    setDisplayModalReminder(false)
  }

  const confirmAudit = async (action: any, type: string) => {
    setSubContractorData((prevData) => ({
      ...prevData,
      status: type === 'validate' ? 'conforme' : 'non_conforme',
    }));
    await validateOrRefuseAudit(currentAccount._id, selectedEntity._id, action.actionId, type, action.versionProductId, action.relatedProductId, action.subContractor.id)
    setDisplayModalConfirm(null)
    setDisplayModal(false)
    fetchAudits();
  }

  const updateFinality = async (action, finality) => {
    await updateSubContractorFinality(action.subContractor.id, finality.value)
    setSelectedFinality(finality)
    fetchAudits();
  }

  const baseNextReminder = new Date('2025-05-20')
  const dateNow = new Date()

  // ===============

  useEffect(() => {
    fetchAudits();
  }, []);

  useDidUpdate(async () => {
    fetchAudits();
  }, [selectedEntity?._id, currentAccount?._id]);

  const fetchAudits = async () => {
    if (selectedEntity?._id) {
      try {
        const result = await fetchEntityAuditActions(selectedEntity._id);
        if (result.error) {
          throw new Error(result.error);
        }
        if (result.success) {
          setEntityAuditActions(result.data);
        }
      } catch (error) {
        console.error("Error fetching entity audit actions:", error);
        toast.error(t('audits.error'))
      }
    }
    else {
      setEntityAuditActions([]);
    }
  }

  const handleNewSubContractorChange = (event) => {
    const { name, value } = event.target;
    setNewSubContractor(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const handleNewSubContractorCreation = async () => {
    try {
      const subContractorData = {
        ...newSubContractor,
        finality: newSubContractor.finality.value,
        accountId: currentAccount._id,
        entityId: selectedEntity._id
      }
      const result = await createSubContractor(subContractorData);

      if (result.error) {
        throw new Error(result.error);
      }
      if (result.success) {
        toast.success(t('audits.newSubContractorSuccess'))
        setDisplayModalNewSubContractor(false);
        setNewSubContractor(newSubContractorInitialState);
        setEntityAuditActions(prevState => [...prevState, result.data]);
      }

    } catch (error) {
      console.error("Error creating subContractor :", error);
      toast.error(t('audits.newSubContractorError'))
    }
  }

  return (
    <>
      {loading ?
        <Container flex justifyContent='center' mt='200px'>
          <Loader loaderOnly={true} />
        </Container>
        : !selectedEntity ?
          <NoData dangerouslySetInnerHTML={{
            __html: t('audits.noEntity', { account: currentAccount?.companyName }),
          }} />
          :
          <>
            {LEGAL_OPS.includes(activeAccount._id) && selectedEntity && <NewSubContractor onClick={() => setDisplayModalNewSubContractor(true)}><BsPlusCircle size='19px' /><span>{t('audits.newSubContractor')}</span></NewSubContractor>}

            <Container flex gap='23px' height='100%' mt='33px' position='relative'>
              {
                Object.entries(audits).map(([statusName, status]) => (
                  <ActionsContainer>
                    <ContainerTitle color={status.color} backgroundColor={status.backgroundColor}>
                      <Chip icon={status.icon} color={status.color} backgroundColor='transparent' /><span>{t(status.label)} ({status.actions.length + status.actionsToVerify?.length})</span>
                    </ContainerTitle>
                    <List>
                      {status.actionsToVerify && status.actionsToVerify
                        .map((action, index) => {
                          return (
                            <Action key={action.id || index} action={action} index={index} statusName={statusName} />
                          );
                        })}
                      {statusName === 'completed' && status.actions.length !== 0 &&
                        <AuditsCompletedText>
                          <SeparatorCompleted />
                          <span>
                            {t('audits.auditsCompleted', { number: status.actions.length })}
                          </span>
                          <SeparatorCompleted />
                        </AuditsCompletedText>
                      }
                      {status.actions
                        .map((action, index) => {
                          return (
                            <Action key={action.id || index} action={action} index={index} statusName={statusName} />
                          );
                        })}
                    </List>
                  </ActionsContainer>
                ))
              }
              <Modal size={{ height: !displayModalReminder && '75%', width: '83%' }} show={displayModal} onClose={() => closeModal()} closeColor='#00000057'>
                <Container flex alignItems='center' flexWrap='wrap'>
                  <TitleModal>{selectedAction?.subContractor?.name}</TitleModal> <Separator />
                  <ChipStatus selectedAction={selectedAction} />
                  {subContractorData?.responsableEmail && <><Separator /><ContactInfoModal><MdOutlineEmail /> <Span>{subContractorData.responsableEmail}</Span></ContactInfoModal></>}
                  {subContractorData?.responsablePosition && <><Separator /><ContactInfoModal><FaRegUserCircle /> <Span>{subContractorData.responsablePosition}</Span></ContactInfoModal></>}
                  {LEGAL_OPS.includes(activeAccount._id) && <><Separator /><LegalOpsElement fontSize="1.2em"><BsFillPinFill size={12} /><span>{t('common.legalOps')}</span></LegalOpsElement></>}
                  {LEGAL_OPS.includes(activeAccount._id) && selectedAction?.surveyLink && <><Separator /><LegalOpsElement fontSize="1.2em"><a target='_blank' href={selectedAction?.surveyLink}><IoIosLink size={14} /><span>{t('common.edit')}</span></a></LegalOpsElement></>}
                </Container>
                <ContainerSubTitleModal>
                  {LEGAL_OPS.includes(activeAccount._id) ?
                    <Container width='620px'>
                      <StyledInputComponent
                        height='40px'
                        placeholder={t('common.finality')}
                        type={'select'}
                        options={getFinalityOptions()}
                        onChange={(_value: any, _field: any, _event: any, option: { label: string; value: string }) => updateFinality(selectedAction, option)}
                        value={selectedFinality}
                        sharable={false}
                        noOptionsMessage={() => t('actionPlan.no_entity_available')}
                        roundedBorder='17px'
                        fontFamily='Nunito'
                      />
                    </Container>
                    : <Span>{selectedAction?.finality}</Span>
                  }
                  {((selectedAction?.status === 'completed' || selectedAction?.status === 'verify') && !displayModalReminder) &&
                    <Container flex justifyContent='flex-end' gap='21px'>
                      {selectedAction.subContractorStatus === 'ongoing' && selectedEntity &&
                        <>
                          <Button fontFamily='Nunito' label={t('common.refuse')} icon={RxCross2} customColor='#FF746F' padding='7px 25px' labelOptions={{ fontSize: '1em' }} onClick={() => { setDisplayModalConfirm('refuse') }} />
                          <Button fontFamily='Nunito' label={t('common.validate')} icon={audits.completed.icon} customColor='#326C82' padding='7px 25px' labelOptions={{ fontSize: '1em' }} onClick={() => { setDisplayModalConfirm('validate') }} />
                        </>
                      }
                    </Container>}
                </ContainerSubTitleModal>
                <BackgroundResponse display={(selectedAction?.status === 'completed' || selectedAction?.status === 'verify') ? 'grid' : 'block'}>
                  {displayModalReminder ?
                    <Container p={'10px 20px'}>
                      <RemindersTitle>{t('audits.autoReminders')} :</RemindersTitle>
                      <RemindersWarning>
                        <RiErrorWarningLine size={21} />
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t('audits.reminderWarning'),
                          }} />
                      </RemindersWarning>
                      <RemindersBody>
                        <LabelReminder fw='600'>
                          {t('audits.nextReminder')} :
                          {nextReminder !== null ?
                            <>
                              <span style={{ textDecoration: 'line-through' }}>{dayjs(baseNextReminder).format('DD.MM.YYYY')}</span>
                              : <span>{dayjs(dateNow.setMonth(dateNow.getMonth() + nextReminder)).format('DD.MM.YYYY')}</span>
                            </>
                            :
                            <span>{dayjs(baseNextReminder).format('DD.MM.YYYY')}</span>
                          }
                        </LabelReminder>
                        <Container flex column gap='12px'>
                          <LabelReminder fw='500'>{t('audits.intermediateReminder')} :</LabelReminder>
                          <ReminderChoice>
                            <label htmlFor={'reminder_now'}>
                              <input
                                name={'reminder'}
                                onChange={() => setNextReminder(0)}
                                type='radio'
                                id={'reminder_now'}
                                value={'now'}
                              />
                              {t('common.now')}
                            </label>
                            <label htmlFor={'reminder_3'}>
                              <input
                                name={'reminder'}
                                onChange={() => setNextReminder(3)}
                                type='radio'
                                id={'reminder_3'}
                                value={'now'}
                              />
                              {t('common.inSomeMonths', { n: '3' })}
                            </label>
                            <label htmlFor={'reminder_6'}>
                              <input
                                name={'reminder'}
                                onChange={() => setNextReminder(6)}
                                type='radio'
                                id={'reminder_6'}
                                value={'now'}
                              />
                              {t('common.inSomeMonths', { n: '6' })}
                            </label>
                            <label htmlFor={'reminder_9'}>
                              <input
                                name={'reminder'}
                                onChange={() => setNextReminder(9)}
                                type='radio'
                                id={'reminder_9'}
                                value={'now'}
                              />
                              {t('common.inSomeMonths', { n: '6' })}
                            </label>
                            <label htmlFor={'reminder_12'}>
                              <input
                                name={'reminder'}
                                onChange={() => setNextReminder(12)}
                                type='radio'
                                id={'reminder_12'}
                                value={'now'}
                              />
                              {t('common.inSomeMonths', { n: '12' })}
                            </label>
                          </ReminderChoice>
                        </Container>
                        <Container flex column gap='12px' mb='20px'>
                          <LabelReminder fw='500'>{t('audits.enterMailReminder')} :</LabelReminder>
                          <Container width='350px'>
                            <StyledInputComponent name={'emailReminder'} defaultValue={subContractorData.responsableEmail || selectedAction?.subContractor?.contactEmail} type='text' height='30px' roundedBorder='25px' clearable='true' value={emailReminder} setValue={setEmailReminder} onChange={event => setEmailReminder(event.target.value)} />
                          </Container>
                        </Container>
                      </RemindersBody>
                      <Container flex justifyContent='space-between' alignItems='center' mt='13px'>
                        <Container flex alignItems='center'>
                          <MdKeyboardArrowLeft color='#326C82' size='20px' />
                          <TextReturn onClick={() => { setDisplayModalReminder(false) }}>{t('common.cancel').toUpperCase()}</TextReturn>
                        </Container>
                        <Button
                          customColor='#326C82'
                          label={t('common.validate')}
                          fontFamily='Nunito'
                          labelOptions={{ fontWeight: '700', fontSize: '1em' }}
                          style={{ 'width': '15%' }}
                          onClick={() => { handleChangeReminder() }}
                        />
                      </Container>
                    </Container>
                    : displayModalConfirm ?
                      <ContainerConfirm>
                        <p dangerouslySetInnerHTML={{
                          __html: displayModalConfirm === 'validate' ? t('audits.confirmAudit', { name: selectedAction?.subContractor?.name }) : t('audits.refuseAudit', { name: selectedAction?.subContractor?.name }),
                        }} />
                        <Container flex justifyContent='center' mt='13px' width='100%'>
                          <Container flex column alignItems='center' width='100%'>
                            <Button style={{ 'width': '25%' }} fontFamily='Nunito' label={t(`common.${displayModalConfirm}`)} icon={displayModalConfirm === 'validate' ? audits.completed.icon : RxCross2} customColor={displayModalConfirm === 'validate' ? '#326C82' : '#FF746F'} padding='7px 25px' labelOptions={{ fontSize: '1em' }} onClick={() => { confirmAudit(selectedAction, displayModalConfirm) }} />
                            <TextReturn style={{ 'marginTop': '20px' }} onClick={() => { setDisplayModalConfirm(null) }}>{t('common.cancel').toUpperCase()}</TextReturn>
                          </Container>
                        </Container>
                      </ContainerConfirm>
                      :
                      <>
                        <ResponseHeader>
                          <SearchBar placeholder={t('common.search')} query={searchQuery} setQuery={setSearchQuery} colors={{ background: 'white' }} roundedBorder={true} />
                          {/* <a onClick={() => setDisplayModalReminder(true)}>{t('audits.changeReminders').toUpperCase()}</a> */}
                        </ResponseHeader>
                        {(selectedAction?.status === 'completed' || selectedAction?.status === 'verify') ?
                          <ResponseContainer>
                            {subContractorData?.responses && Object.entries(subContractorData?.responses).map(([key, value]: [string, string]) => {
                              const { questionLabel, response } = formatSubcontractorQuestionAndResponses(key, value);
                              const normalizedQuery = normalizeString(searchQuery.toLowerCase().trim());
                              if (!searchQuery
                                || normalizeString(questionLabel.toLowerCase()).includes(normalizedQuery)
                                || normalizeString(response.toLowerCase()).includes(normalizedQuery)) {
                                const regex = /\.(pdf|docx|xlsx)$/i;
                                return (
                                  <SharedItem
                                    questionValue={response}
                                    value={questionLabel}
                                    valid={true}
                                    key={key}
                                    type='questions'
                                    cursor={regex.test(value) ? 'pointer' : null}
                                    onClickFn={() => {
                                      if (regex.test(value)) {
                                        setShowAvailableDocuments({ show: true, fileName: value });
                                      }
                                    }}
                                    backgroundItems='white'
                                  />
                                );
                              }
                            })}
                          </ResponseContainer> :
                          selectedAction?.status === 'ongoing' ?
                            <NoAnswers>{t('audits.noAnswers')} <span>🥹</span></NoAnswers> :
                            <></>
                        }
                      </>}
                </BackgroundResponse>

              </Modal>
              {selectedEntity && showAvailableDocuments.show && selectedAction && (
                <Modal
                  size={{ height: '100%' }}
                  show={showAvailableDocuments?.show}
                  title={t('actionPlan.available_documents')}
                  onClose={() => setShowAvailableDocuments({ show: false, fileName: null })}>
                  <FileExplorer
                    admin={true}
                    selectedEntity={selectedEntity}
                    selectedFileKey={`public/${currentAccount._id}/${selectedEntity._id}/Audits/${selectedAction.subContractor?.name}/${showAvailableDocuments.fileName}`}
                    showEntitySelection={false}
                    disableBreadcrumb
                  />
                </Modal>
              )}

              {displayModalNewSubContractor && (
                <Modal
                  top="25%"
                  show={displayModalNewSubContractor}
                  onClose={() => setDisplayModalNewSubContractor(false)}
                  rowGap='21px'>
                  <NewSubContractorTitle><MdOutlineEmail color={'#3B4B5F'} size={30} /><span>{t('audits.sendSurvey')}</span></NewSubContractorTitle>
                  <Container>
                    <Label>{t('audits.enterMail')} :</Label>
                    <StyledInputComponent type='text' name='contactEmail' placeholder={'Ex: patrick@dipeeo.com'} fontFamily='Nunito' roundedBorder='17px' value={newSubContractor.contactEmail} onChange={handleNewSubContractorChange} />
                  </Container>
                  <Container>
                    <Label>{t('audits.subContractorInfos')} :</Label>
                    <Container flex gap={'16px'}>
                      <StyledInputComponent type='text' name='subContractorName' placeholder={t('audits.enterSubContractor')} fontFamily='Nunito' roundedBorder='17px' value={newSubContractor.subContractorName} onChange={handleNewSubContractorChange} />
                      <StyledInputComponent type='select' name='finality' placeholder={t('common.finality')} fontFamily='Nunito' roundedBorder='17px' options={getFinalityOptions()} value={newSubContractor.finality} onChange={(_value: any, _field: any, _event: any, option: { label: string; value: string }) => {
                        setNewSubContractor(prevState => {
                          return ({
                            ...prevState,
                            finality: option
                          })
                        })
                      }} />
                    </Container>
                  </Container>
                  <Container flex justifyContent='center' mt='13px'>
                    <Button
                      customColor='#326C82'
                      label={t('common.validate')}
                      fontFamily='Nunito'
                      labelOptions={{ fontWeight: '700', fontSize: '1.1em' }}
                      style={{ 'width': '35%' }}
                      onClick={() => handleNewSubContractorCreation()}
                    />
                  </Container>
                </Modal>
              )}


            </Container>
          </>
      }
    </>
  );
};

export default Audits;


