import { customFetch } from '@utils/utils';

export const fetchAccountSubContractors = async (accountId: string): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/getAccountSubcontractors`,
    'POST',
    JSON.stringify({
      accountId,
    }),
    true
  );
};

export const fetchLegalOfficerClientsSubContractors = async (legalOfficerEmail: string): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/getLegalOfficerClientsSubContractors`,
    'POST',
    JSON.stringify({
      legalOfficerEmail,
    }),
    true
  );
};

export const fetchAllSubContractors = async (): Promise<any> => {
  return await customFetch(`${process.env.REACT_APP_HEROKU_TARGET}/audit/getAllSubContractors`, 'GET', null, true);
};

export const downloadAllSubContractorsInventory = async (): Promise<any> => {
  return await customFetch(`${process.env.REACT_APP_HEROKU_TARGET}/audit/downloadFullInventory`, 'GET', null, true);
};

export const downloadFilteredSubContractorsInventory = async (accountId: string, entityId?: string, finality?: string): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/downloadInventory`,
    'POST',
    JSON.stringify({
      accountId,
      entityId,
      finality,
    }),
    true
  );
};

export const downloadLegalOfficerClientInventory = async (legalOfficerEmail: string): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/downloadLegalOfficerClientInventory`,
    'POST',
    JSON.stringify({
      legalOfficerEmail,
    }),
    true
  );
};

export const fetchEntityAuditActions = async (entityId: string): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/getEntityActions`,
    'POST',
    JSON.stringify({
      entityId,
    }),
    true
  );
};

export const fetchAccountAuditActions = async (accountId: string): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/getAccountActions`,
    'POST',
    JSON.stringify({
      accountId,
    }),
    true
  );
};

export const createSubContractor = async (params: {
  accountId: string;
  entityId: string;
  finality: string;
  contactEmail: string;
  subContractorName: string;
}): Promise<any> => {
  return await customFetch(`${process.env.REACT_APP_HEROKU_TARGET}/audit/subContractor/create`, 'POST', JSON.stringify(params), true);
};
