import React, { useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Checkbox, Divider } from '@mui/material';

import { Container, FiltersButton, MoreOptions, Placeholder } from '@components-new/atoms';
import { SearchBar } from '@components-new/molecules';

import { CustomStack, DataTableContainer, HoverCCT, NoDataContainer, Row } from './DataTable_.style';
import { DataTableProps } from './DataTable.type';
import { t } from 'i18next';

const DataTable = ({
  loading,
  columns,
  rows,
  onRowClick,
  rowActions = null,
  maximumRowActionsDisplayed,
  checkboxSelection,
  allItemsSelection,
  setAllItemsSelection,
  checkRowSelection,
  handleRowSelection,
  headerComponents,
  searchQuery,
  setSearchQuery,
  filtersCount,
  searchPlaceholder = 'Rechercher ...',
  onFilterClick,
  onFilterReset,
  noDataText,
  disableSearchBar,
  disableFilters,
  tableInventory = false,
  customHeader = null
}: DataTableProps) => {
  // TODO!: Refactor this
  const [cctVisibility, setCctVisibility] = useState('hidden');

  if (rowActions !== null && rowActions.length) addRowActions(rowActions, columns, maximumRowActionsDisplayed);

  const inMenuAction = rowActions?.filter(rowAction => rowAction.showInMenu) ?? [];

  const renderHeaderComponents = (placement: 1 | 2 | 3) => {
    return headerComponents
      .filter(headerComponent => headerComponent.placement === placement)
      .map((headerComponent, index) => <React.Fragment key={`header-component-${placement}-${index}`}>{headerComponent.component}</React.Fragment>);
  };

  return (
    <>
      {customHeader ?
        customHeader
        :
        <Container flex mb='1rem' mrChild='2rem' height='40px'>
          {headerComponents && renderHeaderComponents(1)}
          {!disableSearchBar && <SearchBar placeholder={searchPlaceholder} query={searchQuery} setQuery={setSearchQuery} />}
          {headerComponents && renderHeaderComponents(2)}
          {!disableFilters && (
            <FiltersButton setSearchValue={setSearchQuery} onFilterClick={onFilterClick} onFilterReset={onFilterReset} filtersCount={filtersCount} />
          )}
          {headerComponents && renderHeaderComponents(3)}
        </Container>
      }

      <DataTableContainer tableInventory={tableInventory}>
        {/* Header */}
        <Row headerRow headerRowInventory={tableInventory} >
          {checkboxSelection && (
            <Container key={`header-data-checkbox`} className='col-0-5' bolder center>
              <Checkbox
                checked={allItemsSelection}
                onClick={e => {
                  e.stopPropagation();
                }}
                onChange={e => setAllItemsSelection(prevState => !prevState)}
                disabled={loading}
              />
            </Container>
          )}
          {columns.map((column: any, i: number) => {
            const { headerName, width, bolder, center } = column;
            return (
              headerName === t('common.warranties') ?
                <Container key={`header-data-${i}`} className={width} flex bolder={bolder} center={center}>
                  <HoverCCT visibility={cctVisibility}>{t('inventory.CCTHover')}</HoverCCT>
                  <span onMouseEnter={() => setCctVisibility('visible')}
                    onMouseLeave={() => setCctVisibility('hidden')}>
                    {headerName}
                  </span>
                </Container> :
                <Container key={`header-data-${i}`} className={width} flex bolder={bolder} center={center}>
                  {headerName}
                </Container>
            )
          })}
        </Row>
        <Divider />
        {/* Content */}
        <Container className='container-fluid data-table' flex column p='0' overflowX='hidden' height='100%' width='100%'>
          {/* Loading State */}
          {loading ? (
            <>
              {[1, 2, 3, 4, 5].map(i => (
                <React.Fragment key={`loading-fragment-${i}`}>
                  <Row key={`row-loading-${i}`}>
                    {checkboxSelection && <Container className={'col-0-5'}></Container>}
                    {columns.map(column => {
                      const { width, placeholder, center } = column;
                      return (
                        <Container className={width} flex center={center}>
                          {placeholder ? <Placeholder variant={placeholder.variant} width={placeholder.width} /> : null}
                        </Container>
                      );
                    })}
                  </Row>
                  {i < 5 && <Divider variant={!tableInventory ? 'middle' : undefined} />}
                </React.Fragment>
              ))}
            </>
          ) : // No Data Loaded
            rows?.length === 0 ? (
              <NoDataContainer>{noDataText}</NoDataContainer>
            ) : (
              // Data Loaded
              <Virtuoso
                style={{ overflowX: 'hidden', overflowY: 'scroll', flex: '1 1 auto' }}
                data={rows}
                itemContent={(index, row) => {
                  return (
                    <>
                      <Row
                        key={`row-data-${index}`}
                        onClick={tableInventory ? undefined : (e) => {
                          e.preventDefault();
                          onRowClick(row);
                        }}
                        backlog={!row.draft && !row.active && !tableInventory}
                        rowInventory={tableInventory}
                        hover={!tableInventory && true}>
                        {checkboxSelection && (
                          <Container className='col-0-5' bolder center>
                            <Checkbox
                              checked={checkRowSelection && checkRowSelection(row)}
                              onClick={e => {
                                e.stopPropagation();
                              }}
                              onChange={e => handleRowSelection(row)}
                            />
                          </Container>
                        )}
                        {columns.map((column, index) => {
                          if (column.field === 'rowActions') {
                            return (
                              <Container key={`columns-data-${index}`} className={column.width}>
                                <CustomStack direction='row' spacing={1}>
                                  {rowActions?.map((rowAction, index) => {
                                    const { icon: Icon, onClick, showInMenu, isDisabled } = rowAction;
                                    const shouldHideRowAction = isDisabled && typeof isDisabled === 'function' ? isDisabled(row) : false;
                                    if (shouldHideRowAction) {
                                      return null;
                                    }
                                    return (
                                      !showInMenu && (
                                        <Container
                                          key={`row-actions-${index}`}
                                          // forwardRef={attributeRef}
                                          pointer>
                                          <Icon
                                            size={24}
                                            onClick={e => {
                                              e.stopPropagation();
                                              onClick(row);
                                            }}
                                          />
                                          {/* <Tippy content={label} reference={attributeRef} placement='bottom' /> */}
                                        </Container>
                                      )
                                    );
                                  })}
                                  {inMenuAction.length > 0 && <MoreOptions options={inMenuAction} />}
                                </CustomStack>
                              </Container>
                            );
                          }
                          const fieldValue = row[column.field];
                          const FieldComponent = typeof fieldValue === 'function' ? fieldValue : null;
                          const RenderCellComponent = column.renderCell ? column.renderCell({ row }) : null;

                          return (
                            <Container key={index} className={row.customSizeCols ? row.customSizeCols[index] : column.width} flex center={column.center}>
                              {RenderCellComponent ? RenderCellComponent : FieldComponent ? <FieldComponent /> : fieldValue}
                            </Container>
                          );
                        })}
                      </Row>
                      {(!tableInventory || index !== rows.length - 1) && <Divider variant={!tableInventory ? 'middle' : undefined} />}
                    </>
                  );
                }}
              />
            )}
        </Container>
        {!tableInventory && <Divider />}
      </DataTableContainer>
    </>
  );
};

export default DataTable;

// Utils
export const addRowActions = (rowActions, columns, maximumRowActionsDisplayed) => {
  if (!columns.some(column => column.field === 'rowActions')) {
    columns.push({
      field: 'rowActions',
      headerName: '',
      width: 'col-1',
      bolder: true,
      center: false,
      // renderHeader: () => <React.Fragment />,
      // getActions: params => RowActions(params, rowActions, maximumRowActionsDisplayed),
    });
  }
};
